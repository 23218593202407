import React, { useState, useEffect } from 'react';
import { Button, Modal, InputGroup, Form } from 'react-bootstrap'

import { Forgotpassword } from '../../Hooks/UsePostBackend';

const ForgotModal = (props) => {
    console.log("props_props_props",props);
    const [isforgot, setIsforgot] = useState(true)
    const [pwdshow, setpwdshow] = useState(false)
    const [confirmpwdshow, setconfirmpwdshow] = useState(false)
    const [Name, setName] = useState("")


    const Forgotdata = async (data) => {
        console.log("dataaa-p---", data);
        await Forgotpassword(data)
    }
    const forgot = async () => {
        
        const data ={
            user_name :Name
        }
        await Forgotdata(data)

    }
    return (
        <>
            <Modal show={isforgot} centered size="md">
                <Modal.Header className='pb-0'>
                    <h5 className='themeclr'>Forgot Password</h5>
                    <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>
                </Modal.Header>
                <Modal.Body className='py-4 modal_forn_new'>
                    <div>
                        <label>User Name</label>
                        <InputGroup className="mb-3">
                            <Form.Control aria-label="Email"
                                type='Name'
                                value={Name}
                                required
                                onChange={(e) => { setName(e.target.value) }}
                                placeholder='UserName' />
                        </InputGroup>
                        <div className='text-center'>
                            <button className='btn getstarted' onClick={() => forgot()}>Reset</button>
                        </div>
                        <div className="text-center a_with_text_modal">
                            <p className='text-center'>Back to login? <a href="javascript:void(0)" className="modal_redirect_a" onClick={() => { props.onDismiss(); props.onOpenlogin() }}>Login</a></p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgotModal
export const  style = {
      
                        
    minWidth: '300px',
    minHeight: '55px',
    border: '4px solid #4F862D',
    padding: '16px',
    color: '#fff',
    background:'black',
    
}

export const iconTheme = {
    primary: '#F6DF2D',
    secondary: '#000',
}

export const  position= {
    position: "top-center"
}
import React , {useEffect, useState} from 'react'
import { getSettingsData } from '../Hooks/UseBackend';
import cicon from '../Assets/images/grunimg/c1.png';
import tele from '../Assets/images/grunimg/tele1.png';
import whatsappicon from '../Assets/images/grunimg/what1.png';
import axios from 'axios';
import { backurl } from '../Config/env';


const Footer = () => {
  const [settingdata, setsettingdata] = useState([]);
  const [cmsdata, setCmsData] = useState([]);

  useEffect(() => {
    getData()
    getCmsData()
  })


  const getCmsData = async () => {

    try {
      axios
        .get(backurl + "/api/get/home")
        .then(res => {
          // console.log("Ressssssssssss", res?.data)
          setCmsData(res?.data)

        })
        .catch()
    } catch (err) {
      console.log("Errrrrrr", err)
    }
  }


  const cmsFilter = (data) => {
    try {
      let filterdata = cmsdata?.find((el) => el.Identifier == data && el.Status == "true");
      // console.log("filterdata", filterdata)
      if (filterdata) {
        return filterdata;
      }
    } catch (err) {

    }
  }

  const getData = async () => {
    var settingdata = await getSettingsData();
    setsettingdata(settingdata);
  }
  return (
    cmsdata && cmsdata.length > 0 ? <>
      <div className='footer'>
        <div className='container container-theme'>
          <div className='row newletterrow'>

            <div className='col-sm-8 '>
              <p className='text-left textclr'>{cmsFilter("landing_banner09")?.Description == undefined ? "" : cmsFilter("landing_banner09")?.Description}</p>
            </div>
            <div className='col-sm-4'>

              {(cmsFilter("landing_banner09")?.ImageLink1 != undefined || cmsFilter("landing_banner09")?.ImageURI1 != undefined) ?
                <> {<a href={cmsFilter("landing_banner09")?.ImageLink1 != undefined ? cmsFilter("landing_banner09")?.ImageLink1 : <>{ }</>} target='_blank'>< img className='cicon' src={cmsFilter("landing_banner09")?.ImageURI1 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner09")?.ImageURI1 : ""} /></a>}  </> : <>  </>}

              {(cmsFilter("landing_banner09")?.ImageLink2 != undefined || cmsFilter("landing_banner09")?.ImageURI2 != undefined) ?
                <> {<a href={cmsFilter("landing_banner09")?.ImageLink2 != undefined ? cmsFilter("landing_banner09")?.ImageLink2 : <>{ }</>} target='_blank'>< img className='cicon' src={cmsFilter("landing_banner09")?.ImageURI2 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner09")?.ImageURI2 : ""} /></a>}  </> : <>  </>}

              {(cmsFilter("landing_banner09")?.ImageLink3 != undefined || cmsFilter("landing_banner09")?.ImageURI3 != undefined) ?
                <> {<a href={cmsFilter("landing_banner09")?.ImageLink3 != undefined ? cmsFilter("landing_banner09")?.ImageLink3 : <>{ }</>} target='_blank'>< img className='cicon' src={cmsFilter("landing_banner09")?.ImageURI3 != undefined ? backurl + "/Images/" + cmsFilter("landing_banner09")?.ImageURI3 : ""} /></a>}  </> : <>  </>}

            </div>
          </div>
        </div>
      </div>
    </> : <></>

  )
}

export default Footer;
import React, { useEffect, useState } from 'react';
import { getAccount } from '../Hooks/useAccounts';
import { getPoolInfoLimit, getPoolLength, getUser } from '../Hooks/useStake';
import Footer from './Footer';
import Header from './Header';
import LockedStackCont from './LockedStackCont';
import logoicon from '../Assets/images/logoicon.png'

const LockedStake = () => {
    const [poolLimit, setPoolLimit] = useState([]);
    const [poolLength, setPoolLength] = useState(0);
    const [accountInfo, setAccountInfo] = useState(getAccount());

useEffect(() =>{
    getData();
})

    
    const getData = async () => {
      

        var data = await getPoolLength();
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)
        if (pool) {
            setPoolLimit(pool);
        }
     

    }
    return(
        <div className='lockedstake'>
           
            <Header />
            <div className='container container-theme mt-4'>
            {/* <div className='showloader minwidth-534 text-center' id="loader_div">
                <div className='loader ' >
                    <img src={logoicon} className="spin" />
                    
                </div>

            </div> */}
                <div className='br-14 bgtheme bgcolor text-dark p-sm-5 p-4 text-center bannerbox d-flex align-items-center justify-content-center'>
                    <h1 className='innerheading'>Locked Staking</h1>
                </div>
                <div className='row mb-5 row_center_locke'>

                {poolLimit && poolLimit.map((val, index) => <LockedStackCont  datas={val} pid={index} accountInfo={accountInfo}/>)}
                </div>
              
            
               
            </div>
            <Footer />
        </div>
    )
}

export default LockedStake
import axios from "axios";

import isEmpty from "is-empty";

import toast, { Toaster } from 'react-hot-toast';
import {style,iconTheme,position} from './useToast';
import { backurl, frontendurl } from "../Config/env";
import { sleep } from "./useContract";


export const UseCreateUser = (param) => {
    try {
         axios
               .post(backurl + "/api/user/create", param)
               .then(async (res) => {
                   if (res.status === 200 && res.data.success === true) {
                       toast.success(`${res.data.data}`, {
                          position:position.position,
                           style:style,
                           iconTheme: iconTheme,
                       })
                       setTimeout(() => {
                           window.location.href = frontendurl;
                       },1000);
   
                   }else{
                    {
                       toast.error(`${res.data.data}`, {
                          position:position.position,
                           style:style,
                           iconTheme: iconTheme,
                       })
   
                   }}
   
               })
               .catch(err => {
   
               }
               );
   
       }
       catch (e) {
           console.log("error", e);
       } 
   
};

export const UseAdminLogin = (param) => {

console.log("param-------------------dasd--",param);
    axios
        .post(backurl + "/api/validate/login", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
               
                sessionStorage.setItem("username", res.data.username)

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
await sleep(1000)
                window.location.href = frontendurl;

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};


export const Forgotpassword = (param) => {

console.log("param-------",param);
    axios
        .post(backurl + "/api/validate/forgotpassword", param)
        .then(async (res) => {


            if (res.status === 200 && res.data.success === true) {
               
                sessionStorage.setItem("username", res.data.username)

                toast.success(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                })
await sleep(5000)
                window.location.href = frontendurl;

            } else {
                toast.error(`${res.data.data}`, {
                    position: position.position,
                    style: style,
                    iconTheme: iconTheme,
                }
                )
            }
        }
        ).catch(err => {
        }
        );

};

// export const Forgotpassworddata = (param) => {

//     console.log("param-------", param);
//     axios
//         .post(backurl + "/api/validate/forgotpassworddata", param)
//         .then(async (res) => {

//             console.log("res.data.success", res.data.success);
//             if (res.status === 200 && res.data.success === true) {

//                 // sessionStorage.setItem("username", res.data.username)

//                 toast.success(`${res.data.data}`, {
//                     position: position.position,
//                     style: style,
//                     iconTheme: iconTheme,
//                 })
//                 await sleep(1000)
//                 // window.location.href = frontendurl;

//             } else {
//                 toast.error(`${res.data.data}`, {
//                     position: position.position,
//                     style: style,
//                     iconTheme: iconTheme,
//                 }
//                 )
//             }

//             if(res.status == 200){
//                 console.log("resssssssssss", res.data)
//                 return res;
//             }

//         }
//         ).catch(err => {
//         }
//         );

// };

export const getuserdata = async (param) => {

    try {
        let res = await axios.post(backurl + "/api/validate/getuserdata", param);
        console.log("get_userdata", res)
        if (res.status == 200) {
            return res
        }
    } catch (err) {
        console.log("eeeeeeee_userdata", err)
    }

};

export const Forgotpassworddata = async (param,data) => {
console.log("param_param_V",param,data);
    try {
        // let data ={
            
        // }
        let res = await axios.post(backurl + "/api/validate/Forgotpassworddata", param);
        console.log("get_userdata", res)
        if (res.status == 200) {
            return res
        }
    } catch (err) {
        console.log("eeeeeeee_userdata", err)
    }

};

export const emailotp = async (param) => {

    try {
        console.log("parammmmmmmmmmmmmmmm",param);
        let res = await axios.post(backurl + "/api/validate/emailotp", param);
        console.log("get_userdata", res)
        if (res.status == 200) {
            return res
        }
    } catch (err) {
        console.log("eeeeeeee_userdata", err)
    }

};
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Land from './Component/Land1';
import Launchpad from './Component/Launchpad';
import LockedStake from './Component/LockedStaking';
import News from './Component/News';
import Newsdetails from './Component/Newsdetails';
import Articles from './Component/Articles';
import toast, { Toaster } from 'react-hot-toast';
import Map from './Component/Map';

function App() {
  return (
    <div className="App">
       <BrowserRouter >
       <div><Toaster /></div>
       <Routes>
          <Route path="/" element={<Land />} />
          <Route path="/:auth/:email" element={<Land />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route path="/staking" element={<LockedStake />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsdetails" element={<Newsdetails />} />
          <Route path="/newsdetails/:id" element={<Newsdetails />} />
          <Route path="/latestarticles/:id" element={<Articles />} />
          <Route path="/map" element={<Map />} />
          
       </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import toast, { Toaster } from 'react-hot-toast';
import Token from "../Assets/images/token.jpg"
import newdetail from "../Assets/images/newdetail.jpg"
import Token1 from "../Assets/images/token1.jpg"
import { getArticle1Data, getArticle2Data, getArticle3Data, getArticle4Data, getNewsDesDataLimit, getNewsDesDataOnedata, getOneArticlesdata, getTotalArticleImage, UseContact, useContact, useContract } from '../Hooks/UseBackend';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { backurl } from '../Config/env';
import { validateSubscriper, validateUserContact } from '../Hooks/useValidation';
import bannerimg from '../Assets/images/grunimg/banner1.png';
import logoss from "../Assets/images/logo.png";
const Newsdetails = () => {

    const [newsdata,setnewsdata] = useState({})
    const [newsdesdata,setnewsdesdata] = useState([])
    const [name,setname] = useState("");
    const [Email,setEmail] = useState("");
    const [Comments,setComments] = useState("");
    const [website,setwebsite] = useState("");
    const [errors, seterrors] = useState({})
    const [ArticleImage1,setArticleImage1] = useState([])
    const [ArticleImage2,setArticleImage2] = useState([])
    const [ArticleImage3,setArticleImage3] = useState([])
    const [ArticleImage4,setArticleImage4] = useState([])
    const [newid,setnewsid] = useState("")

    useEffect(() =>{
        getData()
    },[])

    const getData = async() =>{
        var newsid = window.location.pathname.split("/")[2];
        setnewsid(newsid)
        const data = await getOneArticlesdata(newsid);

        setnewsdata(data)
       
        const newsLimit = await getNewsDesDataLimit();
       console.log("newsLimit",newsLimit);
        setnewsdesdata(newsLimit)


        const data1 = await getArticle1Data();
        
        setArticleImage1(data1)
        const data2 = await getArticle2Data();
        setArticleImage2(data2)
        const data3 = await getArticle3Data();
        setArticleImage3(data3)
        const data4 = await getArticle4Data();
        setArticleImage4(data4)
        


        }



        const onSubmit = async (e) => {

            e.preventDefault();
            const UserData = {
                name: name,
                Email: Email,
                Comments: Comments,
                website: website,
               
                
                
            };
            
            const { errors, isValid } = await validateSubscriper(UserData);
            if (!isValid) {
               
                seterrors(errors)
            }
            else {
               
               await UseContact(UserData)
               
            }
    
           
    
        };
    return(
        <div className="news">
            <Header />
            <div><Toaster /></div>
            <div className='newshead'>

          
           
                <div className='newdetail'>
                <img src={backurl + `/Images/${newsdata && newsdata?.logoURI}`}/>
                </div>
              
            </div>
            <div className='container container-theme'>
               
           <div className='row'>
              
                <div className='col-lg-9 col-md-7 col-12'>
                    <div className='newdetailsec'>
                        <h2>{newsdata?.Title}</h2>
                        {/* <h2>The standard chunk of Lorem Ipsum used since the 1500s.</h2> */}
                        <div className='dayscmment'>
                            {/* <p><i class="fas fa-calendar"></i> {newsdata && newsdata?.updatedAt?.substring(0,10)}</p> */}
                            {/* <p><i class="fas fa-comments"></i> Add Comment</p> */}
                        </div>
                        <p className='textconete'>
                       <b> {newsdata?.Heading}</b>
                       {/* <b>Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC</b> */}
                        </p>

                        
                        {/* <p className='textconete'>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.
                                <br></br><br></br>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.
                                <br></br><br></br>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                            </p> */}

<p className='textconete'>
                        {ReactHtmlParser(newsdata?.Description)}
                        </p>
                        {newsdata?.NewsLink === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink}>
</iframe>
                        
                    </div>}
                    

                        {/* {newsdata?.Description != null ?
                        <>   <p className='textconete'>
                        {ReactHtmlParser(newsdata?.Description)}
                        </p>
                        {newsdata?.NewsLink === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink}>
</iframe>
                        
                    </div>}
                    </> :<></>}
                     
                    {newsdata?.Description1 != null ? 
                    <>            <p className='textconete'>
                    {ReactHtmlParser(newsdata?.Description1)}
                    </p>
                    {newsdata?.NewsLink1 === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink1}>
</iframe>

                        
                    </div>}</> : <></>}
            
                    {newsdata?.Description2 != null ? 
                    <>         <p className='textconete'>
                    {ReactHtmlParser(newsdata?.Description2)}
                    </p>
                    {newsdata?.NewsLink2 === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink2}>
</iframe>
                        
                    </div>}</> : <></>
}               
                   
                    {newsdata?.Description3 != null  ? 
                    <>    <p className='textconete'>
                        {ReactHtmlParser(newsdata?.Description3)}
                        </p>
                        {newsdata?.NewsLink3 === null ? <></> :
                        <div className='vdieo_hide'>
                    <iframe src={newsdata?.NewsLink3}>
</iframe>
                        
                    </div>}</> : <></>} */}
                    
                        <div class="imgdive">
                           
      {/* { newid === "6388ab18dd975d15eb883927" ? 
       ArticleImage1 && ArticleImage1.map((item) =>{
       <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>})
        : newid === "6388ab2cdd975d15eb883948"  ?  
        <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div> : newid === "6388b3bb06417923d0ea2a8a"  ? 
     <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div> : <div class="imgs">
        <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
    </div>} */}


    {/* { newid === "639328781f7a1cf17802ac6a" && ArticleImage1.map((item) =>
    <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>)}
       { newid === "639328811f7a1cf17802ac6f" && ArticleImage2.map((item) =>
    <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>)}
       { newid === "639328891f7a1cf17802ac74" && ArticleImage3.map((item) =>
    <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>)}
       { newid === "639335481f7a1cf17802b065" && ArticleImage4.map((item) =>
    <div class="imgs">
           <img src={backurl + `/Images/${item?.logoURI}`} />
       </div>)} */}


             
       {/* <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div>
       <div class="imgs">
           <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" />
       </div> */}
   </div>
                    </div>
                    {/* <div className='form_loist'>
                        <h2>Add Comment</h2>
                      <form onSubmit={onSubmit}>
                       <div>
                       <div className='labelforme'>
                       <label>Comment</label>
                        <textarea  
                        value={Comments}
                        onChange={(e) => { setComments(e.target.value)}}rows="4" >
                            
                        </textarea>
                       <span className="text-danger f-12 d-block text-left">{errors.Comments}</span>
                       </div>

                       <div className='inputfoe'>
                       <div className='labelforme'>
                       <label>Name</label>
                        <input 
                        type="text"
                        value={name}
                        onChange={(e) => { setname(e.target.value)}}></input>
                       <span className="text-danger f-12 d-block text-left">{errors.name}</span>
                       </div>

                       <div className='labelforme'>
                       <label>Email</label>
                        <input 
                        value={Email}
                        onChange={(e) => { setEmail(e.target.value)}}
                        type="text"/>
                       <span className="text-danger f-12 d-block text-left">{errors.Email}</span>
                       </div>

                       <div className='labelforme'>
                       <label>Website</label>
                        <input 
                        type="text"
                        value={website}
                                          onChange={(e) => { setwebsite(e.target.value)}}/>
                       <span className="text-danger f-12 d-block text-left">{errors.website}</span>
                       </div>

                       </div>
                       </div>
                       <div className='labelformecheck mt-3'>
                      
                      
                       </div>
                       <button  className='submitfomer'>Submit Comment</button>
                      </form>
                    </div> */}
                </div>
                <div className='col-lg-3 col-md-5 col-12'>
                    <div className='recent_post'>
                        <h2>Recent News Post</h2>
                        {newsdesdata && newsdesdata.map((item) =>
                         (item.status == true ? 
                        <div className='recentblogdet'>
                       
                            <div className='recentdeis'>
                            <img src={backurl + `/Images/${item?.logoURI}`}/>
                            {/* <img src={bannerimg} /> */}
                            </div>
                       
                        
                            <a href={`/newsdetails/${item._id}`}>{item?.Title}</a>
                            {/* <a href={`/newsdetails/${item._id}`}>Lorem Ipsum Content.</a> */}
                       
                        </div> : <></>)
                        )}
                      
                        
                    </div>
                    
                </div>
           </div>
           </div>
            <Footer />
        </div>
    )
}

export default Newsdetails
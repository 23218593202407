import React, { useState, useEffect } from 'react';
import { Button, Modal, InputGroup, Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Forgotpassworddata ,getuserdata,emailotp} from '../../Hooks/UsePostBackend'
import { validateUserRegister } from '../../Hooks/useValidation'
import Validator from "validator";

const ForgotModal = (props) => {
    console.log("props_props_V", props);

    let location = useLocation();

    const [emaildata, setemail] = useState('')
    console.log("emaildata_emaildata_emaildata", emaildata);

    useEffect(() => {
        console.log("Location_data", location.pathname.split('/')[2])
        let emailId = location.pathname.split('/')[2];
        setemail(emailId)
        const email = {
            email: emailId
        }
        getdata(email)
    }, []);

    const getdata = async (data) => {
        const userdata = await getuserdata(data)
        console.log("userdataaa", userdata);
        let otp = userdata.data.data.Otp;
        const OTP = Buffer.from(otp).toString('base64');
        let checkotp = location.pathname.split('/')[1];
        console.log("checkotp", checkotp, OTP, checkotp == OTP)
        if (Number(otp) > 0 && checkotp == OTP) {
            let emailId = location.pathname.split('/')[2];
            const emaildatas = {
                email: emailId
            }
            setotpstatus(true)
            const otpdata = await emailotp(emaildatas);
        }
        if(otp=="0"){
            props.onDismiss()
        }


    }

    const [isforgot, setIsforgot] = useState(true)
    const [pwdshow, setpwdshow] = useState(false)
    const [errors, seterrors] = useState({})
    const [otpstatus, setotpstatus] = useState(false)

    const [password, setpassword] = useState("")
    const [confirmpassword, setconfirmpassword] = useState("")

    const [confirmpwdshow, setconfirmpwdshow] = useState(false)

    const [passwordneweye, setpasswordneweye] = useState(false)
    const [passwordconfirmeye, setpasswordconfirmeye] = useState(false)

    const forgot = async (data) => {
        console.log("data_data", data);
        let errors = {};
        let isValid = true;
        var passwordpattern = new RegExp(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
        if (!passwordpattern.test(data.password)) {
            console.log("passwordpattern_passwordpattern", passwordpattern);
            errors.password = " Use 8 or more characters with a mix of letters, numbers & symbols";
        }
        if (Validator.isEmpty(data.password)) {
            isValid = false;
            errors.password = "Password field is required";
        }
        if (Validator.isEmpty(data.confirmpassword)) {
            isValid = false;
            errors.confirmpassword = "Confirm password field is required";
        }

        if (!Validator.equals(data.password, data.confirmpassword)) {
            isValid = false;
            errors.confirmpassword = "Passwords must match";
        }

        console.log("errorss----", errors);
        if (!isValid) {
            seterrors(errors)
        } else {


            if (otpstatus) {

                const responddata = await Forgotpassworddata(data);
              

                if(responddata?.data?.success ==true){
                    props.onDismiss()

                }
            }
        }


    }




    const forgotdata = async (e) => {
        e.preventDefault()
        const data = {
            password: password,
            confirmpassword: confirmpassword,
            auth: props.auth,
            email: emaildata

        }
        await forgot(data)
    }


    const showNewPassword = (e) => {


        var e = document.getElementById("password");
        console.log("e_e_V", e);
        if (e.type === "password") {
            setpasswordneweye(true)
            e.type = "text";
        } else {
            setpasswordneweye(false)
            e.type = "password";
        }
    };


    const showConfirmPassword = (e) => {

        var e = document.getElementById("confirmPassword");
        if (e.type === "password") {
            setpasswordconfirmeye(true)
            e.type = "text";
        } else {
            setpasswordconfirmeye(false)
            e.type = "password";
        }
    };








    return (
        <>
            <Modal show={isforgot} centered size="md">
                <Modal.Header className='pb-0'>
                    <h5 className='themeclr'>Forgot Password</h5>
                    {/* <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button> */}
                    <button className='btn btn-link themeclr close' onClick={() => props.onDismiss()}><span className='fa fa-times'></span></button>

                </Modal.Header>
                <Modal.Body className='py-4 modal_forn_new'>
                    <div>
                        <label>New Password</label>
                        <InputGroup className="mb-3">
                            <Form.Control aria-label="password" type='password' id="password" value={password}
                                onChange={(e) => { setpassword(e.target.value) }} placeholder='password' />

                            <InputGroup.Text >
                                {!passwordneweye ?
                                    <span className="fa fa-eye-slash pwd_hover_eye" onClick={showNewPassword}></span> :
                                    <span className="fa fa-eye pwd_hover_eye" onClick={showNewPassword}></span>
                                }

                            </InputGroup.Text>
                        </InputGroup>


                        <span className="text-danger f-12 mb-1 d-block text-left">{errors.password}</span>
                        <label>Confirmpassword</label>
                        <InputGroup className="mb-3">
                            <Form.Control aria-label="Confirmpassword" id="confirmPassword" type='password' value={confirmpassword} required
                                onChange={(e) => { setconfirmpassword(e.target.value) }} placeholder='Confirmpassword' />
                            <InputGroup.Text >
                                {!passwordconfirmeye ?
                                    <span className="fa fa-eye-slash pwd_hover_eye" onClick={showConfirmPassword}></span> :
                                    <span className="fa fa-eye pwd_hover_eye" onClick={showConfirmPassword}></span>
                                }

                            </InputGroup.Text>
                        </InputGroup>
                        <span className="text-danger f-12 mb-1 d-block text-left">{errors.confirmpassword}</span>



                        <div className='text-center'>
                            <button className='btn getstarted' onClick={(e) => forgotdata(e)}>Reset</button>
                        </div>
                        <div className="text-center a_with_text_modal">
                            <p className='text-center'>Back to login? <a href="javascript:void(0)" className="modal_redirect_a" onClick={() => { props.onDismiss(); props.onOpenlogin() }}>Login</a></p>
                        </div>
                    </div>

                </Modal.Body>

            </Modal>
        </>
    )
}

export default ForgotModal
import Validator from "validator";
import isEmpty from "is-empty";




export const validateUserContact = (data) => {
    

    let errors = {};
    let isValid =true;
    if (Validator.isEmpty(data.First_name)) {
        isValid = false;
        errors.First_name = "Field is required";
    }
    if (Validator.isEmpty(data.Last_name)) {
        isValid = false;
        errors.Last_name = "Field is required";
    }
    if (Validator.isEmpty(data.email)) {
        isValid = false;
        errors.email = "Field is required";
    }
    var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
   
    if (!pattern.test(data.email)) {
        isValid = false;
        errors.email = "Email is not Valid";
      }
    if (Validator.isEmpty(data.comments)) {
        isValid = false;
        errors.comments = "Field is required";
    }
  
    if (Validator.isEmpty(data.phoneNo)) {
        isValid = false;
        errors.phoneNo = "Field is required";
    }
    // if (Validator.isEmpty(data.country)) {
    //     isValid = false;
    //     errors.country = "Field is required";
    // }
   
return {
        errors,
        isValid: isEmpty(errors)
        
    };
};


export const validateCreateProposal = (data) => {
    

    let errors = {};
    let isValid =true;
    if (Validator.isEmpty(data.Question)) {
        isValid = false;
        errors.Question = "Question field is required";
    }
    if (Validator.isEmpty(data.Description)) {
        isValid = false;
        errors.Description = "Description field is required";
    }
   
return {
        errors,
        isValid: isEmpty(errors)
        
    };
};


export const validateSubscriper = (data) => {
    
console.log("data",data);
    let errors = {};
    let isValid =true;
  
  
    var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
   
    if (!pattern.test(data.Email)) {
        isValid = false;
        errors.Email = "Email is not Valid";
      }
      if (Validator.isEmpty(data.name)) {
        isValid = false;
        errors.name = "Field is required";
    } 
   
      if (Validator.isEmpty(data.Email)) {
        isValid = false;
        errors.Email = "Field is required";
    }  
    if (Validator.isEmpty(data.Comments)) {
        isValid = false;
        errors.Comments = "Field is required";
    }  
   
    if (Validator.isEmpty(data.website)) {
        isValid = false;
        errors.website = "Field is required";
    } 

    var urlpattern = new RegExp(     /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
   
    if (!urlpattern.test(data.website)) {
        isValid = false;
        errors.website = "Url is not Valid";
      }

    

   
return {
        errors,
        isValid: isEmpty(errors)
        
    };
};


export const validateUpdateToken = (data) => {
    
    const re = /^[-+]?[0-9]+\.[0-9]+$/;
    let errors = {};
    let isValid =true;
    if (Validator.isEmpty(data.settingname)) {
        isValid = false;
        errors.settingname = "Field is required";
    }
    if (Validator.isEmpty(data.settingvalue)) {
        isValid = false;
        errors.settingvalue = "Field is required";
    }
    if(data.feeforproposal >= 0){
        
        if(!(!/\D/.test(data.feeforproposal) || data.feeforproposal.match(re))){
            isValid = false;
            errors.feeforproposal = "Invalid input"
        }
        
    }
    else {
        isValid = false;
        errors.feeforproposal = "Field is required"
    }

    if(data.feeforvoting >= 0){
       
        if(!(!/\D/.test(data.feeforvoting) || data.feeforvoting.match(re))){
            isValid = false;
            errors.feeforvoting = "Invalid input"
        }
    }
    else {
        isValid = false;
        errors.feeforvoting = "Field is required"
    }
   
return {
        errors,
        isValid: isEmpty(errors)
        
    };
};


export const validateUserRegister = (data) => {
    
    let errors = {};
    let isValid =true;
  
    data.user_name = !isEmpty(data.user_name) ? data.user_name : "";
    data.email = !isEmpty(data.email) ? data.email : "";
    data.password = !isEmpty(data.password) ? data.password : "";
    data.confirmpassword = !isEmpty(data.confirmpassword) ? data.confirmpassword : "";
    if (Validator.isEmpty(data.user_name)) {
        isValid = false;
        errors.user_name = "Field is required";
    } 
    if (Validator.isEmpty(data.email)) {
        isValid = false;
        errors.email = "Field is required";
    } 
    var pattern = new RegExp(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/);
    if (!pattern.test(data.email)) {
        isValid = false;
        errors.email = "Email is not Valid";
      }

 
    var passwordpattern = new RegExp( /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/);
      if (!passwordpattern.test(data.password)) {
        isValid = false;
           errors.password = " Use 8 or more characters with a mix of letters, numbers & symbols";
      }
      if (Validator.isEmpty(data.password)) {
        isValid = false;
        errors.password = "Password field is required";
    }
    if (Validator.isEmpty(data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Confirm password field is required";
    }
   
    if (!Validator.equals(data.password, data.confirmpassword)) {
        isValid = false;
        errors.confirmpassword = "Passwords must match";
    }
    return {
        errors,
        isValid: isEmpty(errors)
        
    };
};